
import barba from '@barba/core';
import KeenSlider from 'keen-slider';


export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.verticalSlider01()
            this.verticalSlider02()
            this.horizonalSlider01()
            this.horizonalSlider02()
        })
        barba.hooks.after((data) => {
            this.verticalSlider01()
            this.verticalSlider02()
            this.horizonalSlider01()
            this.horizonalSlider02()
        });
    }
    verticalSlider01(){
        const indexScroll = document.querySelector("#l-hero__image");
        if(indexScroll) {
            const animation = { duration: 15000, easing: (t) => t }

            new KeenSlider('.vertical--slider01', {
                loop: true,
                mode:'free',
                renderMode: "performance",
                drag: false,
                vertical: true,
                // rtl:true,
                slides: {
                    perView: 2,
                    spacing: 26,
                },
                breakpoints: {
                    '(min-height: 576px)': {
                        slides: {
                            perView: 2,
                            spacing: 26,
                        },
                    },
                    '(min-height: 768px)': {
                        slides: {
                            perView: 2.5,
                            spacing: 40,
                        },
                    },
                },
                created(s) {
                    s.moveToIdx(1.1, true, animation)
                },
                updated(s) {
                    s.moveToIdx(s.track.details.abs + 1.1, true, animation)
                },
                animationEnded(s) {
                    s.moveToIdx(s.track.details.abs + 1.1, true, animation)
                },
            });
        }
    }
    verticalSlider02(){
        const indexScroll = document.querySelector("#l-hero__image");
        if(indexScroll) {
            const animation = { duration: 15000, easing: (t) => t }

            new KeenSlider('.vertical--slider02', {
                loop: true,
                mode:'free',
                renderMode: "performance",
                drag: false,
                vertical: true,
                // rtl:true,
                slides: {
                    perView: 2,
                    spacing: 26,
                },
                breakpoints: {
                    '(min-height: 576px)': {
                        slides: {
                            perView: 2,
                            spacing: 26,
                        },
                    },
                    '(min-height: 768px)': {
                        slides: {
                            perView: 2.5,
                            spacing: 40,
                        },
                    },
                },
                created(s) {
                    s.moveToIdx(1.1, true, animation)
                },
                updated(s) {
                    s.moveToIdx(s.track.details.abs + 1.1, true, animation)
                },
                animationEnded(s) {
                    s.moveToIdx(s.track.details.abs + 1.1, true, animation)
                },
            });
        }
    }

    horizonalSlider01(){
        const indexLoop = document.querySelector("#l-hero__image");
        if(indexLoop) {
            const animation = { duration: 14000, easing: (t) => t }

            new KeenSlider('.horizonal--slider01', {
                loop: true,
                //mode:'free',
                renderMode: "performance",
                drag: false,
                slides: {
                    perView: 1.3,
                    spacing: 20,
                },
                breakpoints: {
                    '(min-width: 420px)': {
                        slides: {
                            perView: 1.5,
                            spacing: 20,
                        },
                    },
                    '(min-width: 576px)': {
                        slides: {
                            perView: 1.5,
                            spacing: 20,
                        },
                    },
                    '(min-width: 768px)': {
                        slides: {
                            perView: 2,
                            spacing: 20,
                        },
                    },
                    '(min-width: 992px)': {
                        slides: {
                            perView: 1.5,
                            spacing: 20,
                        },
                    },
                },
                created(s) {
                    s.moveToIdx(2, true, animation)
                },
                updated(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
                animationEnded(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
            });
        }
    }

    horizonalSlider02(){
        const indexLoop = document.querySelector("#l-hero__image");
        if(indexLoop) {
            const animation = { duration: 14000, easing: (t) => t }

            new KeenSlider('.horizonal--slider02', {
                loop: true,
                //mode:'free',
                renderMode: "performance",
                drag: false,
                slides: {
                    perView: 1.3,
                    spacing: 20,
                },
                breakpoints: {
                    '(min-width: 420px)': {
                        slides: {
                            perView: 1.5,
                            spacing: 20,
                        },
                    },
                    '(min-width: 576px)': {
                        slides: {
                            perView: 1.5,
                            spacing: 20,
                        },
                    },
                    '(min-width: 768px)': {
                        slides: {
                            perView: 2,
                            spacing: 20,
                        },
                    },
                    '(min-width: 992px)': {
                        slides: {
                            perView: 1.5,
                            spacing: 20,
                        },
                    },
                },
                created(s) {
                    s.moveToIdx(2, true, animation)
                },
                updated(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
                animationEnded(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
            });
        }
    }
    
}
