
import barba from '@barba/core';
import anime from 'animejs';

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init (){
       const heroCatch = document.querySelectorAll(".l-hero__catch")
        if(heroCatch){
            let regexp = /[\u{3000}-\u{301C}\u{3041}-\u{3093}\u{309B}-\u{309E}]/mu;
            let heroPrimary = document.querySelectorAll('.l-hero__catch--en')
            if(heroPrimary.length > 0){
                heroPrimary.forEach( (h,i) => {
                    let pr_str = h.textContent.split("");
                    let str = "";
                    pr_str.map( s => {
                        str += "<span class='hero__str'>" + s  + "</span>";
                    })
                        
                    h.innerHTML = str;
                })
            }

            let heroSecondary = document.querySelectorAll('.l-hero__catch-text')
            if(heroSecondary){
                heroSecondary.forEach( (h,i) => {
                    let sc_str = h.textContent.split("");
                    let str = "";
                    sc_str.map( s => {
                        str += "<span class='hero__str'>" + s  + "</span>";
                    })
                        
                    h.innerHTML = str;
                })
            }

            
    
            setTimeout(function(){
                let str_animation =  anime.timeline();
                str_animation.add({
                    targets : '#l-hero__image__content',
                    opacity:[0,1],
                    easing: "easeOutQuart",
                    duration:2000
                })
                str_animation.add({
                    targets : '.l-hero__catch--en .hero__str',
                    opacity: [0,1],
                    easing: "easeOutQuart",
                    duration: 100,
                    delay: (el, i) => 40 * (i+1),
                },"-=700")
                .add({
                    targets : '.l-hero__catch-text .hero__str',
                    opacity: [0,1],
                    easing: "easeOutQuart",
                    duration: 1600,
                    delay: (el, i) => 140 * (i+1),
                },"-=700")
            },600)
       }
    }
}