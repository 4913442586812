
import barba from '@barba/core';

export default class{

	constructor(){
        this.index_flow = '#js-index__flow'
        this.index_areas = '.js-index__area'
        barba.hooks.once((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init(){
        const flow = document.querySelector('#js-index__flow');
        if(flow) {
            const areas = document.querySelectorAll(this.index_areas);
            const options = {
                root: null,
                rootMargin: '-50% 0px',
                threshold: 0
            };
            const observer = new IntersectionObserver(doWhenIntersect, options);
            areas.forEach(area => {
                observer.observe(area);
            });
        }
        /**
         * 交差したときに呼びだす関数
         * @param entries
         */
        function doWhenIntersect(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    activateIndex(entry.target);
                }
            });
        }

        /**
         * 目次の色を変える関数
         * @param element
         */
        function activateIndex(element) {
            // 既にactiveの目次を選択
            const currentActiveIndex = document.querySelector('#js-index__list .is-active');
            // 既にactiveになっているものがnull以外はactiveを除去
            if(currentActiveIndex !== null) {
                currentActiveIndex.classList.remove('is-active');
            }
            // 引数で渡されたDOMが飛び先のaタグを選択：activeを付与
            const newActiveIndex = document.querySelector(`a[href='#${element.id}']`);
            newActiveIndex.classList.add('is-active');
        }
    }
}