import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export default class{

	constructor(){

        barba.hooks.afterOnce((data) => {
            this.aboutImg(data.next.container)
            // this.bgChange()
            this.bgChange1(data.next.container)
            this.bgChange2(data.next.container)
            this.bgChange3(data.next.container)

        });
        barba.hooks.beforeLeave((data) => {
            window.removeEventListener("resize",this.resize)
        });
        barba.hooks.after((data) => {
            this.aboutImg(data.next.container)
            // this.bgChange()
            this.bgChange1(data.next.container)
            this.bgChange2(data.next.container)
            this.bgChange3(data.next.container)
        });

    }
    aboutImg(container){
        this.trigger = container.querySelector('.p-about__strength__img');
        this.elem = container.querySelectorAll('.p-about__strength__img');
        if(this.elem) {
            this.elem.forEach((el, i) => {
                gsap.to(el, {
                    scrollTrigger: {
                        trigger: ".p-about__strength__img",
                        start: 'center center' ,
                        endTrigger: ".p-about__strength__item__wrapper",
                        end: "bottom-=120px center", 
                        scrub: 1, 
                        // markers: true,
                        pin: true,
                    }
                });
            })
        }
    }
    bgChange1(container){
        const el = container.querySelector(".js-scroll01");
        if(el){
            ScrollTrigger.create({
                trigger: el,
                // markers: true,
                scrub: true,
                start: "top center",
                end:  "bottom center",
                toggleClass: { targets: ".p-about__strength__img", className: "item01" },
            });
        }
    }
    bgChange2(container){
        const el = container.querySelector(".js-scroll02");
        if(el){
            ScrollTrigger.create({
                trigger: el,
                // markers: true,
                scrub: true,
                start: "top center",
                end:  "bottom center",
                toggleClass: { targets: ".p-about__strength__img", className: "item02" },
            });
        }
    }
    bgChange3(container){
        const el = container.querySelector(".js-scroll03");
        if(el){
            ScrollTrigger.create({
                trigger: el,
                // markers: true,
                scrub: true,
                start: "top center",
                end: "bottom+=600px top",
                toggleClass: { targets: ".p-about__strength__img", className: "item03" },
            });
        }
    }

   
    // bgChange(){
    //     this.image = document.querySelectorAll('.p-about__strength__item');
    //     const backgroundImage = [
    //         'url("/dist/img/about/img-strength01.jpg")',
    //         'url("/dist/img/about/img-strength02.jpg")',
    //         'url("/dist/img/about/img-strength03.jpg")',
    //     ]
    //     // if(this.elem) {
    //     //     this.elem.forEach((el, i) => {
    //             for (let i = 0; i < this.image.length; i++) {
    //                 gsap.to('.p-about__strength__img', {
    //                     scrollTrigger: {
    //                         trigger:this.image[i],
    //                         markers:true,
    //                         // scrub:true,
    //                         start:'top bottom',
    //                         endTrigger: ".p-about__strength__item__wrapper",
    //                         end: "bottom bottom",  
    //                     },
    //                     backgroundImage: backgroundImage[i]
    //                 });
    //             }
    //     //     })
    //     // }
    // }
    
}